import './App.css'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import Header from './components/Header'
import Navigation from './components/Navigation'

function App() {
  return (
    <>
      <Navigation/>
      <div className="text-center font-franklin bg-[#F9F4EC] overflow-x-hidden">
        <section id='inicio'>
          <Header/>
        </section>
        <section id="contacto">
          <ContactForm/>
        </section>
        <Footer/>
      </div>
    </>
  );
}

export default App;
