import how_to_help from '../assets/images/how_to_help.svg'
import donations from '../assets/images/donations.svg'

import paypal from '../assets/images/paypal.svg'
import mercado_pago from '../assets/images/mercado_pago.svg'

import utiles from '../assets/images/utiles.png'
import alimento from '../assets/images/alimento.png'
import manualidades from '../assets/images/manualidades.png'
import ropa from '../assets/images/ropa.png'


import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import axios from 'axios'

const initialOptions = {
  clientId: "AV_nU6RXvsf79whLUBWkyV9aJ50nuySTIjP_AY8baH8ZzWtohsVj8rTVKKFl2a3G06yGkA5ixxcKFxoJ",
  // Add other options as needed
};

const styles = {
  layout: 'horizontal',
  color:  'gold',
  shape:  'rect',
  label:  'donate'
}

function HowToHelp(){
  initMercadoPago('APP_USR-8dbf1bf8-6071-49f0-9a9a-c7a4bd7bb56b', {locale: "es-MX"})
  return (
    <div className='bg-[#F3EBDE] text-[#303030] flex items-center justify-center flex-col md:text-[28px] 2xl:pt-10 text-xl font-franklin' id="donar">
        <div className='flex flex-col'>
          <img src={how_to_help} alt='como ayudar' className='relative top-12 md:h-36 lg:h-44 xl:top-16 2xl:top-10 left-auto right-auto px-6 md:p-0'/>
          <img src={donations} alt='donativos' className=' h-[410px] md:h-[530px] lg:h-[663px] md:-top-11 md:right-5 lg:-top-16 lg:right-6 2xl:w-full relative xl:right-8 xl:-top-[40px] 2xl:-top-20 xs:right-2'/>
        </div>
      
        <div className='flex flex-col justify-center items-center mt-5 md:-mt-5 lg:-mt-5 2xl:mt-0'>
          <h1 className='xl:text-[62px] text-[32px] leading-10 font-franklin font-bold lg:text-[56px] 2xl:text-[68px] w-52 sm:w-full'>¡Tu donativo cambia vidas!</h1>
          <p className='text-center md:w-10/12 md:px-24 px-5 mt-5 md:mt-5 font-montserrat font-semibold xl:text-[28px] lg:mt-10 2xl:w-8/12 2xl:text-[32px] xl:leading-[120%] xs:text-xl'>El costo mensual promedio para dar atención personalizada a una víctima en nuestro programa es de <span className='font-bold text-[#627C4B] xl:text-[32px] 2xl:text-[42px]'>$14,500.00</span></p>
        </div>
      
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-20 2xl:gap-0 mt-16 md:mt-10 w-full sm:w-8/12 md:w-1/2 lg:w-1/2 xl:w-5/12 2xl:w-1/2  xs:gap-14 place-items-center'>
          <div className='flex flex-col gap-5 w-full md:w-full items-center sm:items-end'>
            <div className='flex flex-col items-center gap-5 w-full'>
              {/* <div id="donate-button" className=' sm:w-full xl:w-full 2xl:w-9/12'></div> */}
              {/* <div dangerouslySetInnerHTML={{__html:rawHTMLScript}}></div> */}
              {/*<PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={styles} className='sm:w-full xl:w-full 2xl:w-9/12'/>
              </PayPalScriptProvider>*/}
               <a href='https://www.paypal.com/MX/fundraiser/charity/4475605' className=' sm:w-full xl:w-full 2xl:w-9/12 xl:py-3 2xl:text-[26px] 2xl:py-5 lg:w-10/12 lg:text-lg lg:py-3 xs:text-sm xs:py-3.5 xs:w-3/6 font-lato font-extrabold bg-[#F9C539] text-[#283C7C] text-lg rounded-lg shadow-md shadow-gray-600 hover:shadow-none'>DONAR</a> 
              <img src={paypal} alt='paypal' className='md:w-3/4 2xl:w-6/12 2xl:mt-3'/>
            </div>
          </div>

          <div className='flex flex-col gap-5 w-full md:w-full items-center sm:items-start'>
            <div className='flex flex-col items-center gap-5 w-full'>
               <a href='https://link.mercadopago.com.mx/fundacioncaminoacasa' className='sm:w-full xl:w-full 2xl:w-9/12 xl:py-3 2xl:text-[26px] 2xl:py-5 lg:w-10/12 lg:text-lg lg:py-3 xs:text-sm xs:py-3.5 xs:w-3/6 font-lato font-extrabold bg-[#00AEEF] text-[#FFFFFF] text-lg rounded-lg shadow-md shadow-gray-600 hover:shadow-none'>DONAR</a>
              {/*<Wallet initialization={{preferenceId: "1012192881-3c1284e0-7294-42ae-9774-03de0a4a4562"}}/> */}
              {/* PREFERENCE_ID hacer backend para generar la experiencia personalizada para este botón */}
              <img src={mercado_pago} alt='mercado_pago' className='md:w-3/4 2xl:w-6/12 2xl:mt-3' /> 
            </div>
          </div>
        </div>

        <div className='flex flex-col md:py-16 md:px-0 md:mt-0 lg:p-16 p-5 mt-5 font-montserrat xl:text-[28px] 2xl:text-[32px] lg:text-[26px] leading-[120%] font-semibold w-full items-center'>
          
          <p className='font-bold font-montserrat'>¡También puedes realizar donativos en especie!</p>
          <p className='font-bold font-montserrat'>Estas son nuestras necesidades:</p>
        
          <div className='grid grid-cols-2 md:grid-cols-4 gap-5 mt-8 md:px-16 xl:px-28 lg:px-0 xl:p-0'>
            
            <div className='block max-w-sm'>
              <img src={utiles} alt="utles escolares" className='w-full'/>
              <p className='bg-[#E39C18] text-[#F2EDED] font-bold font-lato text-sm uppercase md:text-lg md:px-2 lg:h-[66px] lg:p-0 h-[53px] md:h-[80px] 2xl:h-[100px] 2xl:text-[26px] flex items-center justify-center'>útiles escolares</p>
            </div>

            <div className='block max-w-sm'>
              <img src={ropa} alt="ropa y zapatos nuevos" className='w-full'/>
              <p className='bg-[#E39C18] text-[#F2EDED] font-bold font-lato text-sm uppercase md:text-lg md:px-2 lg:h-[66px] lg:p-0 h-[53px] md:h-[80px] 2xl:h-[100px] 2xl:text-[26px] flex items-center justify-center'>Ropa y zapatos nuevos</p>
            </div>

            <div className='block max-w-sm'>
              <img src={alimento} alt="alimento" className='w-full'/>
              <p className='bg-[#E39C18] text-[#F2EDED] font-bold font-lato text-sm uppercase md:text-lg md:px-2 lg:h-[66px] lg:p-0 h-[53px] md:h-[80px] 2xl:h-[100px] 2xl:text-[26px] flex items-center justify-center'>Alimento</p>
            </div>

            <div className='block max-w-sm'>
              <img src={manualidades} alt="materiales para manualidades" className='w-full'/>
              <p className='bg-[#E39C18] text-[#F2EDED] font-bold font-lato text-sm uppercase md:text-lg md:px-2 lg:h-[66px] lg:p-0 h-[53px] md:h-[80px] 2xl:h-[100px] 2xl:text-[26px] flex items-center justify-center md:p-10'>Materiales para manualidades</p>
            </div>
          </div>

          <div className='w-full md:w-9/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12 xs:w-[350px] md:flex md:flex-col md:gap-5 md:justify-center md:items-center mt-10 font-montserrat'>
            <p className='xl:text-[28px] 2xl:text-[32px] xs:text-xl'>Todo apoyo es <span className='font-bold xl:text-[30px] 2xl:text-[42px] leading-[120%]'>100% deducible de impuestos</span> y con sistema de transparencia, reportes oportunos de aplicación de recursos.</p>
          </div>
        </div>
        
      </div>


  )
}

export default HowToHelp