import ourWorkImage2 from '../assets/images/our_work_image_2.svg'

function OurWork(){
  return(
    <div className='bg-background-custom py-10 text-[#303030] flex flex-col justify-center items-center' id="nuestra-labor">
      <div className='text-[28px] flex flex-col justify-center items-center px-10 lg:px-0 xl:px-10'>
        <h1 className='font-bold md:text-[52px] text-[32px] lg:text-[46px] xl:text-[52px] 2xl:text-[62px]'>Nuestra labor</h1>
        <p className='font-montserrat font-semibold xs:w-[350px] md:w-10/12 lg:w-11/12 md:px-0 text-xl px-5 mt-4 2xl:w-11/12 lg:text-[26px] lg:leading-[120%] xl:text-[28px] 2xl:text-[32px] xl:leading-[120%]'>
          Somos una organización de la sociedad civil que tiene como objetivo, la atención integral a largo plazo de niñas, 
          niños, adolescentes y jóvenes sobrevivientes de la trata de personas en sus modalidades de explotación sexual, 
          mendicidad forzada y explotación laboral.
        </p>
      </div>
      
      <div className='my-[28px]'>
        <img src={ourWorkImage2} alt="nuestra labor" className=' h-full md:h-[440px] lg:h-[543px] xl:h-[624px] 2xl:h-[818px]'/>
      </div>

      <div className='text-[28px] flex flex-col justify-center items-center'>
        <h1 className='font-bold md:text-[48px] text-[32px] lg:text-[46px] xl:text-[52px] 2xl:text-[62px]'>¿Qué nos diferencia?</h1>
        <p className='font-montserrat font-semibold xs:w-[350px] md:w-10/12 lg:w-10/12 md:px-0 text-xl px-5 lg:p-0 lg:text-[26px] lg:leading-[120%] xl:text-[28px] 2xl:text-[32px] xl:leading-[120%] 2xl:w-11/12 xs:mt-4'>
          Cada sobreviviente que recibimos representa un nuevo proyecto de vida que reconstruir. Por eso, la atención que ofrecemos 
          es integral, multidisciplinaria y personalizada. Entendemos que cada usuario es un ser humano con necesidades y sueños diferentes.
        </p>
      </div>
    </div>
  )
}

export default OurWork