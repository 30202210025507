function ContactForm(){
  return (
    <div className="w-full bg-gray-custom flex flex-col items-center justify-center font-franklin xs:py-10 xl:py-[72px]">
      <h1 className="text-[32px] xl:text-[52px] font-bold">Contacto:</h1>

      <div className="w-full 2xl:w-8/12 xl:w-6/12 md:w-3/4 grid grid-cols-1 md:grid-cols-2 xl:mt-10 gap-5 md:gap-10 place-items-center xs:p-5 xs:mt-5 xs:text-xs 2xl:text-lg">
        <input placeholder="Nombre y apellido" className="bg-[#EEDBB4] p-2 text-[#75674B] uppercase w-full rounded-lg placeholder-[#75674B] font-extrabold leading-8 xs:col-span-2 md:col-span-1 "/>
        <input placeholder="Correo" className="bg-[#EEDBB4] p-2 text-[#75674B] uppercase w-full rounded-lg placeholder-[#75674B] font-extrabold leading-8 xs:col-span-2 md:col-span-1"/>
        <select className="bg-[#EEDBB4] p-3 text-[#75674B] uppercase w-full rounded-lg placeholder-[#75674B] font-extrabold col-span-2 appearance-none select_box">
          <option> Selecciona una opcion... </option>
          <option> Correo </option>
          <option> Quiero hacer un donativo </option>
          <option> Información </option>
          <option> Otro asunto </option>
        </select>
        <textarea placeholder="mensaje" className="bg-[#EEDBB4] p-2 text-[#75674B] uppercase w-full rounded-lg placeholder-[#75674B] font-extrabold leading-8 col-span-2" rows={4}>
        </textarea>

        <button className="bg-[#F4B53E] text-[#1E1E1E] md:col-span-2 uppercase font-bold xs:text-[14px] xs:py-3 xs:mt-4 md:mt-0 xl:text-[22px] 2xl:text-[26px] xl:py-3 2xl:py-4 w-full xs:w-1/2 xl:w-2/6 2xl:w-1/4 rounded-lg shadow-md shadow-gray-800 ">enviar</button>
      </div>
    </div>
  )
}

export default ContactForm