import ourPrograms1 from '../assets/images/our_programs_1.png'
import ourPrograms2 from '../assets/images/our_programs_2.png'
import ourPrograms3 from '../assets/images/our_programs_3.png'
import illustration_programs from '../assets/images/programas_ilustracion.svg'

function OurPrograms(){
    return (
    <div className='w-full  bg-background-custom flex items-start justify-center md:p-12 flex-col gap-10 2xl:p-0 lg:px-8 lg:py-8 2xl:px-20 2xl:py-20'>
      <h1 className='font-bold text-[32px] md:text-5xl lg:text-[52px] 2xl:text-[62px] 2xl:leading-[120%] mt-10 md:mt-0 self-center'>Nuestros Programas</h1>
      
      <img src={illustration_programs} alt='illustration' className='w-36 absolute xs:top-[1760px] xs:-right-0 sm:top-[1760px] sm:-right-0 md:w-[301px] xl:w-[350px] 2xl:w-[441px] md:top-[1870px] md:-right-10 lg:top-[2320px] lg:right-20 xl:top-[2500px] xl:right-16 2xl:right-40 2xl:top-[2650px] '/>

      <div className='w-full flex items-start'>
        <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 lg:gap-8 font-lato px-8 md:px-0 pb-8 md:pb-0'>
          <div className='block md:max-w-[270px] lg:max-w-[317px] xl:max-w-[368px] 2xl:max-w-md'>
            <img src={ourPrograms1} alt="regufio xochimilco" className='w-full'/>
            <h3 className='bg-[#E39C18] text-[#F2EDED] font-bold text-2xl md:text-xl md:py-4 uppercase p-2 xl:py-5 2xl:text-[32px] 2xl:leading-[120%] 2xl:py-7 shadow_text_illustration'>Refugio CDMX niñas </h3>
            <p className='text-left font-montserrat font-semibold 2xl:text-2xl 2xl:leading-[124%] md:mt-3 xl:mt-5 xs:mt-2'>Especializado en la atención de niñas y jóvenes víctimas de explotación sexual.  Ofrecemos  atención integral, multidisciplinaria y personalizada a cada usuaria.</p>
          </div>

          <div className='block md:max-w-[270px] lg:max-w-[317px] xl:max-w-[368px] 2xl:max-w-md'>
            <img src={ourPrograms1} alt="regufio xochimilco" className='w-full'/>
            <h3 className='bg-[#E39C18] text-[#F2EDED] font-bold text-2xl md:text-xl md:py-4 uppercase p-2 xl:py-5 2xl:text-[32px] 2xl:leading-[120%] 2xl:py-7 shadow_text_illustration'>Refugio CDMX niños</h3>
            <p className='text-left font-montserrat font-semibold 2xl:text-2xl 2xl:leading-[124%] md:mt-3 xl:mt-5 xs:mt-2'>Especializado en la atención de niños y jóvenes víctimas de explotación sexual y trabajo forzado. Ofrecemos  atención integral, multidisciplinaria y personalizada a cada usuario.</p>
          </div>

          <div className='block md:max-w-[270px] lg:max-w-[317px] xl:max-w-[368px] 2xl:max-w-md xs:mt-10 sm:mt-0'>
            <img src={ourPrograms2} alt="refugio cancún" className='w-full'/>
            <h3 className='bg-[#E39C18] text-[#F2EDED] font-bold text-2xl md:text-xl md:py-4 uppercase p-2 xl:py-5 2xl:text-[32px] 2xl:leading-[120%] 2xl:py-7  shadow_text_illustration'>Refugio Quintana Roo</h3>
            <p className='text-left font-montserrat font-semibold 2xl:text-2xl 2xl:leading-[124%] md:mt-3 xl:mt-5 xs:mt-2'>Especializado en la atención de niñas y jóvenes víctimas de explotación sexual. Ofrecemos  atención integral, multidisciplinaria y personalizada a cada usuaria.</p>
          </div>

          <div className='block md:max-w-[270px] lg:max-w-[317px] xl:max-w-[368px] 2xl:max-w-md xs:mt-10 sm:mt-0'>
            <img src={ourPrograms3} alt="medio camino" className='w-full'/>
            <h3 className='bg-[#E39C18] text-[#F2EDED] font-bold text-2xl md:text-xl md:py-4 uppercase p-2 xl:py-5 2xl:text-[32px] 2xl:leading-[120%] 2xl:py-7  shadow_text_illustration'>Medio camino</h3>
            <p className='text-left font-montserrat font-semibold 2xl:text-2xl 2xl:leading-[124%] md:mt-3 xl:mt-5 xs:mt-2'>Programa dirigido a sobrevivientes que han salido del refugio. Nos encargamos de ayudarlas con vivienda y otros recursos para que poco a poco recobren su independencia sociolaboral.</p>
          </div>
        </div>
      </div>

      </div>
    )
}

export default OurPrograms