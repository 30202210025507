import footer_logo from '../assets/images/logo_footer.svg'
import footer_logo_center from '../assets/images/logo_footer_center.svg'

import facebook from '../assets/images/social/facebook.svg'
import instagram from '../assets/images/social/instagram.svg'

function Footer(){
  return (
    <>
      <div className="bg-avocado-primary text-[#f2eded] flex flex-col md:flex-row items-center md:justify-between pb-8 sm:p-0">
        
        <div className='w-full xl:w-3/12 md:w-[250px] flex justify-center md:justify-start p-8'>
          <img src={footer_logo_center} alt="fundación camino a casa" className='md:hidden h-[88px]'/>
          <img src={footer_logo} alt="fundación camino a casa" className='hidden md:block'/>
        </div>

        <div className='w-full md:w-7/12 xl:w-5/12 2xl:w-1/2 font-lato font-bold uppercase text-[16px] md:text-lg grid grid-cols-1 md:grid-cols-3 place-items-center gap-4'>
          
          <div className='flex flex-col gap-4 text-left justify-center items-center md:items-start'>
            <a href='#trata-personas'>¿qué combatimos?</a>
            <a href='#nuestra-labor'>nuestra labor</a>
          </div>

          <div className='flex flex-col gap-4 text-left justify-center items-center md:items-start'>
            <a href='#informes'>informes</a>
            <a href='#donar'>donar</a>
          </div>
          
          <div className='flex flex-col gap-10 md:gap-4 textl-left justify-center items-center md:items-start'>
            <a href='#contacto'>contacto</a>
            <div className='grid grid-cols-2 gap-8 sm:gap-4'>
              <a href="">
                <img src={facebook} alt="facebook"/>
              </a>
              <a href="">
                <img src={instagram} alt="instagram"/>
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Footer