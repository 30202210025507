import cinemex from '../assets/images/alianzas/cinemex.svg'
import fundacion_simi from '../assets/images/alianzas/fundacion_dr_simi.svg'

function Alliances(){
  return (
    <div className='md:text-[28px] text-[#F2EDED] bg-avocado-primary md:p-5 p-5 lg:p-5 2xl:p-0 2xl:py-10 flex flex-col 2xl:mt-14 xl:mt-[26px] lg:mt-[26px] justify-center items-center font-lato'>
      <h1 className='w-full xl:text-[38px] text-[26px] 2xl:text-[48px] font-bold uppercase'>Nuestros aliados</h1>
      <div className='md:w-3/4 xl:w-8/12 grid grid-cols-2 mt-5 2xl:mt-8 gap-6 xl:gap-10 justify-items-center 2xl:w-3/5'>
        <img src={cinemex} alt='cinemex' className='md:h-16 lg:h-20 xl:h-[106px]'/>
        <img src={fundacion_simi} alt='cinemex' className='md:h-16 lg:h-20 xl:h-[106px]'/>
      </div>
    </div>
  )
}

export default Alliances