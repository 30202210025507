function ActualSituation(){
  return (
    <div className='md:text-[28px] font-montserrat section_situacion_actual_bg p-0 md:py-10 lg:p-10 bg-contain text-[#F2EDED] flex items-center flex-col' id="informes">
      <div className='md:w-8/12 xl:w-10/12 lg:w-8/12 xs:w-full md:p-0 xs:p-10 lg:p-0'>
        <h1 className='text-2xl md:text-[38px] font-bold uppercase font-lato lg:text-[34px] xl:text-[38px] 2xl:text-[52px] xl:font-black'>Situación actual</h1>
        <h2 className='text-xl xl:text-[28px] mt-4 2xl:mt-10 md:mt-5 font-semibold md:text-[22px] lg:text-[26px] leading-[116%] 2xl:text-[32px] xl:leading-[120%]'>Se estima que alrededor de <span className='font-bold'>50,000 personas</span> son víctimas de trata en América Latina y el Caribe cada año.</h2>
      </div>

      <div className='w-11/12 xl:w-full xs:w-11/12 md:w-full md:px-5 lg:px-0 grid grid-cols-1 md:grid-cols-2 mt-5 xl:mt-[66px] 2xl:mt-20 md:mt-10 xs:mt-0 font-normal md:space-y-1 lg:space-y-10 xs:space-y-5 xl:space-y-0'>
        <div className='flex col-span-2 justify-start gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:-left-6 md:static'>◼︎</p>
          <p className='md:w-1/2 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] 2xl:text-[32px] xl:leading-[120%] font-semibold xs:ml-10 md:ml-0'>Alrededor del <span className='font-bold'>59% de las víctimas</span> de trata a nivel mundial <span className='font-bold'>son explotadas sexualmente</span>, mientras que el <span className='font-bold'>34% son sometidas a trabajo forzado.</span></p>
        </div>
        
        <div className='col-span-2 flex justify-end gap-3 mt-10 xs:mt-0 md:mt-0'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static'>◼︎</p>
          <p className='md:w-2/5 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 relative bottom-10 2xl:text-[32px] xl:leading-[120%] font-semibold md:static xs:ml-10 md:ml-0'> <span className='font-bold'>El 49% de las víctimas son mujeres</span> de las cuales el <span className='font-bold'>23% son niñas.</span></p>
        </div>

        <div className='col-span-2 flex justify-start gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static xs:relative xs:left-0 xs:-top-10'>◼︎</p>
          <p className='md:w-1/2 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 xs:mt-0 font-semibold 2xl:text-[32px] xl:leading-[120%] relative md:static bottom-10 xs:ml-1 md:ml-0'> <span className='font-bold'>El 21 % de las víctimas</span> son hombres de las cuales el <span className='font-bold'>7% son niños.</span></p>
        </div>

        <div className='col-span-2 flex justify-end gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static xs:relative xs:left-0 xs:-top-10'>◼︎</p>
          <p className='md:w-2/5 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 xs:mt-0 font-semibold 2xl:text-[32px] xl:leading-[120%]  relative bottom-10 md:static xs:ml-1 md:ml-0'> La trata de personas genera <span className='font-bold'>$236 billones de dólares al año.</span></p>
        </div>

        <div className='col-span-2 flex justify-start gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static xs:relative xs:left-0 xs:-top-10'>◼︎</p>
          <p className='md:w-1/2 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 xs:mt-0 font-semibold 2xl:text-[32px] xl:leading-[120%] relative bottom-10 md:static xs:ml-1 md:ml-0'> Actualmente hay <span className='font-bold'>más personas en esclavitud que en cualquier otra época de la historia.</span></p>
        </div>
        
        <div className='col-span-2 flex justify-end gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static xs:relative xs:left-0 xs:-top-10'>◼︎</p>
          <p className='md:w-2/5 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 xs:mt-0 font-semibold 2xl:text-[32px] xl:leading-[120%] relative bottom-10 md:static xs:ml-1 md:ml-0'> México ocupa el primer lugar en explotación sexual infantil (pornografía)</p>
        </div>

        <div className='col-span-2 flex justify-start gap-3'>
          <p className='text-[#E39C18] absolute text-2xl left-4 md:static xs:relative xs:left-0 xs:-top-10'>◼︎</p>
          <p className='md:w-1/2 text-left text-lg md:text-[22px] lg:text-[24px] lg:leading-[120%] mt-10 md:mt-0 xs:mt-0 font-semibold 2xl:text-[32px] xl:leading-[120%] relative bottom-10 md:static xs:ml-1 md:ml-0'> Actualmente hay <span className='font-bold'>más personas en esclavitud que en cualquier otra época de la historia.</span></p>
        </div>
      </div>
    </div>
  )
}

export default ActualSituation