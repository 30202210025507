import headerTitle from '../assets/images/header/title.svg'
import headerSubtitle from '../assets/images/header/subtitle.svg'

import ourWorkImage from '../assets/images/our_work_image.svg'

import medicalArea from '../assets/images/medic_area.svg'
import psyArea from '../assets/images/psy_area.svg'
import legalArea from '../assets/images/legal_area.svg'
import educationalArea from '../assets/images/educational_area.svg'

import Slider from './Slider'

// import InstalationSlider from './InstalationsSlider'
// import DifferencesSlider from './DifferencesSlider'
import ActualSituation from './ActualSituation'
import OurWork from './OurWork'

import inst01 from '../assets/images/instalaciones/img_01.png'
import inst02 from '../assets/images/instalaciones/img_02.png'
import inst03 from '../assets/images/instalaciones/img_03.png'
import inst04 from '../assets/images/instalaciones/img_04.png'

import diff01 from '../assets/images/differences/img_01.png'
import diff02 from '../assets/images/differences/img_02.png'
import diff03 from '../assets/images/differences/img_03.png'
import diff04 from '../assets/images/differences/img_04.png'

import OurPrograms from './OurPrograms'
import Alliances from './Alliances'
import HowToHelp from './HowToHelp'

function Header() {
  
  return (
    <>
      <div className="bg-avocado-primary pt-10 md:pt-0 flex items-center justify-center">
        <div id="imageHeader" className='header-image h-[483px] w-full lg:h-[721px] flex items-center justify-center relative'>
          <img src={headerTitle} alt="titulo" className=" absolute top-12 md:top-20 lg:top-28 h-[155px] md:h-[261px] lg:h-[300px]   overflow-hidden"/>
          <img src={headerSubtitle} alt="subtitulo" className="absolute h-12 md:h-[58px] lg:h-[76px] bottom-20  md:bottom-24 lg:bottom-28 overflow-hidden"/>
          <a href="#donar" className='absolute w-1/3 text-sm  py-2 md:w-3/12 lg:w-72 md:py-4 bottom-8 lg:bottom-10 uppercase font-lato font-extrabold md:text-[22px] xl:text-[22px] lg:text-[26px] bg-[#F4B53E] shadow-md shadow-gray-800 rounded-md'>donar</a>
        </div>
      </div>
      
      <div className='overflow-x-hidden'>
        {/* <InstalationSlider/> */}
        <div className="w-full h-60 xl:h-full 2xl:h-full flex justify-center items-center">
          <img src={inst01} alt='img' className='h-full xl:h-[308.72px] 2xl:h-[413px]'/>
          <img src={inst02} alt='img' className='h-full xl:h-[308.72px] 2xl:h-[413px]'/>
          <img src={inst03} alt='img' className='h-full xl:h-[308.72px] 2xl:h-[413px]'/>
          <img src={inst04} alt='img' className='h-full xl:h-[308.72px] 2xl:h-[413px]'/>
        </div>
      </div>
      
      <div className=" w-full bg-background-custom text-[#303030] flex justify-center py-8 px-4 xs:py-12 xs:px-6 md:py-0 md:px-0">
        <div className='w-full md:w-8/12 lg:w-3/5 md:py-10 lg:py-[58px] flex flex-col justify-center items-center'>
          <p className='text-lg text-center font-bold md:text-[22px] md:leading-[120%] xl:text-[28px] lg:text-[26px] 2xl:text-[32px] leading-[120%] font-montserrat'>
            La trata de personas es una forma de esclavitud moderna. Se estima 
            que en América Latina, cada año alrededor de unas 50,000 personas 
            son víctimas de este delito.
          </p>
          <a href="#informes" className='mt-8 w-2/6 text-sm py-2 md:text-lg md:py-3 lg:w-[18rem] lg:py-4 xs:mt-5 uppercase font-lato font-extrabold lg:text-[20px] xl:text-[22px] bg-[#F4B53E] text-black shadow-md shadow-gray-800 rounded-md'>conoce más</a>
        </div>
      </div>
      
      <div className='h-full lg:h-[285px] w-full bg-avocado-primary flex justify-center items-center'>
        <p className='w-full md:w-10/12 lg:w-[1064px] p-6 text-[22px] md:p-0 md:py-9 lg:px-4 lg:p-10 md:text-[28px] md:leading-[120%] lg:text-4xl lg:text-[37px] 2xl:text-[42px] 2xl:w-4/6 leading-[120%] font-montserrat font-semibold text-[#F2EDED]'>Fundación Camino a Casa es una organización que a través de refugios especializados reconstruye la vida de sobrevivientes de trata de personas.</p>
      </div>

      <div className={`h-80 md:h-[600px] lg:h-[800px] xl:h-[800px] md:p-10 flex justify-center items-center flex-col bg-[#EBE3D3]`}>
        <img src={ourWorkImage} alt="Conoce nuestro trabajo" className='relative top-16 md:top-28 lg:top-32 max-w-md lg:max-w-6xl 2xl:max-w-6xl px-3 md:px-0 md:max-w-4xl'/>
        <p className='font-semibold font-montserrat text-xs md:text-[22px] md:leading-[120%] w-52 md:w-[360px] text-[#F2EDED] lg:w-[500px] relative -top-40 md:-top-[320px] lg:-top-[420px] lg:text-[28px] xl:text-[30px] xl:leading-[114%] lg:leading-[120%] 2xl:text-[32px] xl:w-[500px] 2xl:-top-[420px] 2xl:w-[550px] shadow_text_illustration'>Cada sobreviviente que recibimos representa un nuevo proyecto de vida, por eso, la atención que ofrecemos es integral, multidisciplinaria y personalizada.</p>

        <a href="#nuestra-labor" className='w-auto py-2 md:py-4 md:px-12 font-extrabold px-5 bg-[#F4B53E] shadow-md shadow-gray-600 relative bottom-20 md:-top-28 2xl:-top-40 xl:-top-36 lg:-top-30 rounded-md p-1 text-black font-lato uppercase md:text-xl text-sm'>conoce nuestra labor</a>
      </div>

      <OurPrograms/>

      <div className='py-6 md:py-12 md:px-0 lg:px-28 xs:px-0 xs:py-12 flex items-center justify-center flex-col space-y-5 bg-[#EBE3D3]' id="trata-personas">
        <h1 className='font-bold 2xl:font-black text-2xl md:text-[30px] lg:text-[34px] xl:text-[38px] 2xl:text-[52px] uppercase text-[#496035] font-lato'>¿Qué combatimos?</h1>
        
        <p className='xl:w-[1156px] 2xl:w-[1462px] lg:w-5/6 md:w-10/12 px-14 md:px-0 xs:px-8 text-xl md:text-xl lg:text-[22px] leading-[116%] xl:text-2xl 2xl:text-[28px] 2xl:leading-[116%] 2xl:pt-5 text-center font-montserrat font-semibold'>
          La trata de personas es un delito grave y una violación de los derechos humanos que implica el reclutamiento, 
          transporte, transferencia, alojamiento o recepción de personas, utilizando la fuerza, el engaño, el soborno 
          u otras formas de coerción, con el fin de explotarlas.
        </p>

        <p className='xl:w-[1156px] 2xl:w-[1462px] lg:w-5/6 md:w-10/12 px-14 md:px-0 xs:px-8 text-xl md:text-xl lg:text-[22px] leading-[116%] xl:text-2xl text-center font-montserrat font-semibold 2xl:text-[28px] 2xl:leading-[116%] 2xl:pt-5'>
          La explotación puede tomar diversas formas, como explotación sexual, trabajo forzado, servidumbre, mendicidad, 
          extracción de órganos, entre otras.
        </p>
      </div>

      <ActualSituation/>

      <OurWork/>
      
      {/* <DifferencesSlider/> */}
      {/* <div className='differences_bg h-80'></div> */}
      <div className='overflow-x-hidden xl:mt-[22px] xs:mt-5'>
        {/* <InstalationSlider/> */}
        <div className="w-full h-full flex justify-center items-center">
          <img src={diff01} alt='img' className='xl:h-[360px] 2xl:h-[480px]'/>
          <img src={diff02} alt='img' className='xl:h-[360px] 2xl:h-[480px]'/>
          <img src={diff03} alt='img' className='xl:h-[360px] 2xl:h-[480px]'/>
          <img src={diff04} alt='img' className='xl:h-[360px] 2xl:h-[480px]'/>
        </div>
      </div>

      <div className='text-[#303030] md:px-10 px-5 py-8 md:py-14 flex items-start lg:px-8 2xl:px-20 flex-col xs:mt-5'>
        
        <div className='xl:w-full flex justify-start flex-col'>
          <div className='xl:w-full flex flex-col items-start'>
            <h1 className=' font-bold text-[32px] md:text-3xl lg:text-[46px] xl:text-[48px] 2xl:text-[62px] leading-[120%]'>Equipo Multidisciplinario</h1>
            <p className='text-center md:text-left font-semibold font-montserrat sm:text-left sm:w-11/12 lg:text-[26px] text-xl lg:leading-[120%] xl:text-[28px] lg:w-11/12 md:pr-6 mt-6 md:leading-8 xs:w-[348px]'>
              Para poder reconstruir proyectos de vida, necesitamos contar con un equipo 
              multidisciplinario que atienda los cuatro pilares de nuestro modelo de atención:
            </p>
          </div>
        </div>

        <div className='w-full md:w-11/12 lg:w-11/12 2xl:w-[1538px] flex  sm:justify-center md:justify-start'> 
          
          <div className='sm:w-10/12 md:w-full grid grid-cols-2 md:grid-cols-4 md:gap-5 gap-5 text-xl mt-10 font-lato'>
            <div className='block max-m-sm font-bold'>
              <img src={medicalArea} alt='area medica' className='w-full'/>
              <p className='uppercase text-white bg-[#E39C18] md:p-2 text-sm h-14 md:h-14 md:text-lg lg:text-lg lg:py-4 flex items-center justify-center 2xl:text-[26px] xl:py-[15px] 2xl:py-5'>área médica</p>
            </div>

            <div className='block max-w-sm font-bold'>
              <img src={psyArea} alt='area medica' className='w-full'/>
              <p className='uppercase text-white bg-[#E39C18] md:p-2 text-sm h-14 md:h-14 md:text-lg lg:text-lg lg:py-4 flex items-center justify-center 2xl:text-[26px] xl:py-[15px] 2xl:py-5'>área de salud mental</p>
            </div>

            <div className='block max-w-sm 2xl:max-w-md font-bold'>
              <img src={educationalArea} alt='area medica' className='w-full'/>
              <p className='uppercase text-white bg-[#E39C18] md:p-2 text-sm h-14 md:h-14 md:text-lg lg:text-lg lg:py-4 flex items-center justify-center 2xl:text-[26px] xl:py-[15px] 2xl:py-5'>área educativa</p>
            </div>

            <div className='block max-w-sm font-bold'>
              <img src={legalArea} alt='area medica' className='w-full'/>
              <p className='uppercase text-white bg-[#E39C18] md:p-2 text-sm h-14 md:h-14 md:text-lg lg:text-lg lg:py-4 flex items-center justify-center 2xl:text-[26px] xl:py-[15px] 2xl:py-5'>área jurídica</p>
            </div>
          </div>

        </div>
      </div>

      <div className='md:text-[28px] text-[#303030] flex flex-col justify-center items-center 2xl:mt-14 xs:mt-5'>
        <h1 className='w-full text-[32px] font-bold lg:text-5xl xl:text-[52px] 2xl:text-[62px] '>Nuestros logros:</h1>
        <div className='w-full lg:w-10/12 xl:mt-[23px] 2xl:mt-10 xs:mt-8'>
          <Slider/>
        </div>
      </div>      

      <Alliances/>
      <HowToHelp/>
      
    </>
  )
}

export default Header;
