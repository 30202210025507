import React from 'react'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/skyblue'
import ourAchievementIcon from '../assets/images/our_achievements_icon.svg'

import ourAchievements1 from '../assets/images/our_achievements/our_achievements_1.png'
import ourAchievements2 from '../assets/images/our_achievements/our_achievements_2.png'
import ourAchievements3 from '../assets/images/our_achievements/our_achievements_3.png'
import ourAchievements4 from '../assets/images/our_achievements/our_achievements_4.png'
import ourAchievements5 from '../assets/images/our_achievements/our_achievements_5.png'
import ourAchievements6 from '../assets/images/our_achievements/our_achievements_6.png'
import ourAchievements7 from '../assets/images/our_achievements/our_achievements_7.png'
import ourAchievements8 from '../assets/images/our_achievements/our_achievements_8.png'
import ourAchievements9 from '../assets/images/our_achievements/our_achievements_9.png'
import ourAchievements10 from '../assets/images/our_achievements/our_achievements_10.png'

const ourAchievements = [
  {
    image: ourAchievements1,
    title: 'Proporcionamos apoyo especializado a los hijos e hijas de las sobrevivientes, asegurando que reciban atención psicológica, educativa y emocional para darles una vida digna.'
  },
  {
    image: ourAchievements2,
    title: 'Hemos brindado apoyo integral a más de 230 sobrevivientes de trata, proporcionando servicios esenciales como alojamiento seguro, atención médica, asesoría legal, y apoyo psicológico.'
  },
  {
    image: ourAchievements3,
    title: 'Nos aseguramos de que cada sobreviviente reciba un trato respetuoso y equitativo por parte de las autoridades, garantizando que sus derechos sean protegidos.'
  },
  {
    image: ourAchievements4,
    title: 'Ofrecemos programas de capacitación y asesoría basados en los últimos avances neurocientíficos enfocados a superar el trauma y mejorar su bienestar mental y emocional.'
  },
  {
    image: ourAchievements5,
    title: 'Abrimos el primer refugio de alta seguridad en Quintana Roo. Este refugio ofrece un entorno seguro y confidencial, donde las sobrevivientes pueden recibir el apoyo necesario para su recuperación.'
  },
  {
    image: ourAchievements6,
    title: 'Trabajamos con el Instituto Nacional de Psiquiatría Ramón de la Fuente y el Banco Interamericano de Desarrollo en la elaboración de un libro sobre Trata de personas y nuestro modelo de atención.'
  },
  {
    image: ourAchievements7,
    title: 'Muchas de las sobrevivientes se convirtieron en activistas, quienes a través de talleres, actividades de prevención y conferencias, crean conciencia acerca de la trata de personas.'
  },
  {
    image: ourAchievements8,
    title: 'Implementamos programas de prevención en comunidades vulnerables, educando sobre los riesgos de la trata y explotación. Programas que incluyen campañas de concienciación, talleres en escuelas y organizaciones.'
  },
  {
    image: ourAchievements9,
    title: 'Somos parte de un proyecto que realizan la Oficina de las Naciones Unidas contra el Crimen y la Droga (UNODC) y la Secretaría de Gobernación para el fortalecimiento de refugios.'
  },
  {
    image: ourAchievements10,
    title: 'Apoyamos a las sobrevivientes para que continúen su educación a nivel postgrado, brindándoles becas y recursos necesarios. Siendo la educación una herramienta poderosa para su emancipación y empoderamiento.'
  }
]

function Slider(){
  
  const options = {
    type: 'fade',
    perPage: 1,
    arrows: false,
    perMove   : 1,
    pagination: true,
    autoplay: true
  }

  return (
    <>
      <Splide options={options}>
        {
          ourAchievements.map((slide, index) => (     
            <SplideSlide key={ index }>
              <div className='flex justify-center'>
                <img src={slide.image} alt={slide.title} className='sm:h-[330px] md:h-[365px] xl:h-[524px] lg:h-[451px] 2xl:h-[681px] xs:h-40'/>
              </div>
              <div className='flex justify-center items-center flex-col'>
                <div className='p-2 xl:py-[16px]'>
                  <img src={ourAchievementIcon} alt='icon' className='h-11 md:h-[100px] lg:h-[83px] xl:h-[100px]'/>
                </div>
                <div className='lg:w-10/12 text-center font-bold font-montserrat md:text-lg text-xl xl:text-[28px] 2xl:text-[32px] xl:leading-[116%] 2xl:w-[1287px] xl:mb-[38px] 2xl:mb-14 xs:mb-10 xs:w-11/12'>
                  {slide.title}
                </div>
              </div>
            </SplideSlide>      
          ))
        }
      </Splide>
    </>
  )
}

export default Slider