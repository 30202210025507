// import { Link } from 'react-router-dom';

import logo from '../assets/images/LogoFcc.svg';
import { useCallback, useState } from 'react';

function Navigation() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleClickMenu = useCallback(() => {
    let currentState = showNavbar;
    setShowNavbar(!currentState);
  }, [showNavbar]);

  const hiddeMobileMenu = useCallback(() => {
    setShowNavbar(false);
  },[])

  return (
    <>
      <nav className="w-full bg-menu-bg top-0 sticky text-gray-custom text-lg md:text-sm uppercase font-black md:p-5 lg:py-5 flex items-center justify-center z-10 font-lato">
        <div className='flex items-center justify-center py-4 px-5 md:hidden'>
          <a href={'#inicio'} onClick={hiddeMobileMenu}>
            <img src={logo} className="h-11" alt="fundacion camino a casa"/>
          </a>
        </div>
        <div className="w-full inset-y-0 right-0 flex items-center justify-end md:hidden">
          <button type="button" className="m-2 relative inline-flex items-center justify-center rounded-md p-2 text-gray-custom hover:bg-avocado-primary  focus:ring-2 focus:ring-inset focus:ring-[#939858]"
            onClick={handleClickMenu}>
            <span className="absolute -inset-0.5"></span>
            <span className="sr-only">Open main menu</span>
            <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
            </svg>
          </button>
        </div>

        <div className="hidden md:block w-full md:w-11/12 lg:w-10/12">
          <ul className="w-full flex items-center justify-center">
            <menu className="w-1/5">
              <li>
                <a href={'#inicio'}>
                  <img src={logo} className=" relative md:-left-8 lg:-left-20 xl:-left-24 2xl:-left-32 2xl:h-[82px] lg:h-16 md:h-12" alt="fundacion camino a casa"/>
                </a>
              </li>
            </menu>
            <menu className="w-4/5 grid grid-cols-5 place-items-center md:place-items-end ">
              <li>
                <a href={'#trata-personas'}>¿Qué combatimos?</a>
              </li>
              <li>
                <a href={'#nuestra-labor'}>Nuestra labor</a>
              </li>
              <li>
                <a href={'#informes'}>Informes</a>
              </li>
              <li>
                <a href={'#donar'}>Donar</a>
              </li>
              <li>
                <a href={'#contacto'}>Contacto</a>
              </li>
            </menu>
          </ul>
        </div>
      </nav>
      <div className={`w-full text-gray-custom bg-menu-bg opacity-85 transition-all duration-200 sticky top-[65px] z-10 ${showNavbar ? `h-auto` : `h-0`}`}>
        <ul className={`items-center justify-center flex-col gap-5 p-5 text-2xl font-lato font-bold ${showNavbar ? `flex` : `hidden` }`}>
          <li>
            <a href={'#trata-personas'} onClick={handleClickMenu}>¿Qué combatimos?</a>
          </li>
          <li>
            <a href={'#nuestra-labor'} onClick={handleClickMenu}>Nuestra labor</a>
          </li>

          <li>
            <a href={'#informes'} onClick={handleClickMenu}>Informes</a>
          </li>
          <li>
            <a href={'#donar'} onClick={handleClickMenu}>Donar</a>
          </li>
          <li>
            <a href={'#contacto'} onClick={handleClickMenu}>Contacto</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
